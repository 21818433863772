import React, { useEffect, useState } from "react"
import EggProgressBar from './EggProgressbar'
import { remainDays, remainHours, remainMins } from '@util'

export const PoolCard = (props) => {
  const eggIsReady = Date.now()/1000 >= props.targetEggTime;
  const targetTime = eggIsReady ? props.targetWhelpsTime : props.targetEggTime;

  const [remain, setRemain] = useState({
    days: remainDays(targetTime * 1000),
    hours: remainHours(targetTime * 1000),
    mins: remainMins(targetTime * 1000),
})
  useEffect(() => {
    const timer = setInterval(() => {
      setRemain({
        days: remainDays(targetTime * 1000),
        hours: remainHours(targetTime * 1000),
        mins: remainMins(targetTime * 1000),
      })
    }, 30 * 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div className="flex flex-col border-2 border-primary my-8 sm:mx-8">
      <div className="flex flex-col sm:flex-row justify-center">
        <EggProgressBar
          value={props.progress}
        />
        <div className="flex flex-col justify-center m-4">
          <div className="text-xl tracking-wider font-lemon text-primary-whelps md:text-2xl text-center">
            <span className="uppercase text-white">pool</span>
            <span className="text-primary-whelps">{` #${props.id}`}</span>
          </div>
          <div className="text-primary-light text-md leading-8 font-roboto italic text-center">{ eggIsReady ? "Time Remaining For Claiming Whelps" : "Time Remaining For Claiming Egg"}</div>
          <div className="text-md tracking-wider font-lemon text-primary-whelps md:text-4xl text-center">
            {`${remain.days} : ${remain.hours} : ${remain.mins}`}
          </div>
          <div className="hidden md:flex flex-row justify-between text-md leading-8 font-roboto italic text-primary-light">
            <div>Days</div>
            <div className="ml-4">Hours</div>
            <div>Minutes</div>
          </div>
        </div>
        <div className="flex flex-col w-1/4 items-center m-4 justify-center">
          <button
            className={`py-2 w-full mb-8 text-xs lg:text-sm lg:font-bold tracking-wide text-black ${props.eggClaimed || props.targetEggTime > Math.floor(Date.now()/1000) ? "bg-disabled-whelps" : "bg-primary-whelps"} rounded-sm shadow`} disabled={props.eggClaimed || props.claimEggInProgress || props.targetEggTime > Math.floor(Date.now()/1000)} onClick={props.handleClaimEgg}
          >
            <div
              className="tracking-wider uppercase text-white"
            >
              {props.claimEggInProgress ? "claiming..." : (props.eggClaimed ? "egg claimed" : "claim egg")}
            </div>
          </button>

          <button
            className={`py-2 w-full text-xs lg:text-sm lg:font-bold tracking-wide text-black ${props.whelpsClaimed || props.targetWhelpsTime > Math.floor(Date.now()/1000) ? "bg-disabled-whelps" : "bg-primary-whelps"} rounded-sm shadow`} disabled={props.whelpsClaimed || props.claimWhelpsInProgress || props.targetWhelpsTime > Math.floor(Date.now()/1000)} onClick={props.handleClaimWhelps}
          >
            <div
              className="tracking-wider uppercase text-white"
            >
              {props.claimWhelpsInProgress ? "claiming..." : (props.whelpsClaimed ? "whelps claimed" : "claim whelps")}
            </div>
          </button>
        </div>
      </div>
      <div className="px-3 py-6 relative border-t border-primary">
        {/* <div className="absolute bg-primary w-full h-full opacity-20 left-0 top-0" /> */}
        <div className="grid grid-cols-2 md:grid-cols-6 gap-4 justify-items-center">
          {
            props.dragons.map((dt, idx) => (
              <div
                key={idx}
                className="h-40 w-24 flex flex-col justify-center text-center border border-primary-light rounded-md"
              >
                {dt &&
                  (
                    <>
                      <img src={dt.image} alt="" className="object-cover" />
                      <div className="text-sm lg:text-base text-white">{dt.name}</div>
                      <div className="text-sm lg:text-base text-white">{dt.address}</div>
                    </>
                  )
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
