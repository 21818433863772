import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import {
  CONTRACT_ADDRESS_WHELPS_Eth,
  CONTRACT_ADDRESS_WHELPS_Pol
} from "@util/addressHelpers"
import { faqDataOnStake, INVALID_ID } from "@util/whelpsData"
import { NETWORK_ID } from "@util/constantHelpers";
import addresses from "@config/addresses";
import { PoolCard } from "./PoolCard"
import DragonList from "./DragonList"
import abi_whelps from "./../ABIs/abi-whelps.json"
import abi_staking from "./../ABIs/abi-staking.json"
import img_hashes from "./../ABIs/aggregated_hashes.json"
import "./stake.scss"

const Question = (props) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      className="mt-4 text-left faq-item cursor-pointer"
      onClick={() => setOpen(!open)}
    >
      <div className="grid grid-cols-12 items-center">
        <div className="text-xl tracking-wider font-lemon text-light-whelps col-span-10">
          {props.faqTitle}
        </div>
        <div className="col-start-11 col-span-2">
          <img src="/stake/question-fold.png" alt="" className={`max-h-8 object-cover ${open ? "transform rotate-90" : ""}`} />
        </div>
      </div>
      {open &&
        <div className="mt-5 text-lg font-light leading-8 text-primary-light font-roboto-light">
          {props.faqDescription}
        </div>
      }
    </div>
  )
}

const Stake = ({
  chainId, web3, ethWeb3, polWeb3, walletAddress, connected
}) => {
  const eth_chain_id = 1
  const pol_chain_id = 137
  const initWhelps = [
    {
      id: INVALID_ID,
    },
    {
      id: INVALID_ID,
    },
    {
      id: INVALID_ID,
    },
    {
      id: INVALID_ID,
    },
    {
      id: INVALID_ID,
    },
    {
      id: INVALID_ID,
    },
  ]
  const WHELPS_CLAIM_TIMESTAMP = 3888000;
  const EGG_CLAIM_TIMESTAMPS = [
    3024000, // 35 * 24 * 3600
    2419200, // 28 * 24 * 3600
    1814400, // 21 * 24 * 3600
    1209600, // 14 * 24 * 3600
    604800, //  7 * 24 * 3600
    259200 //  3 * 24 * 3600
  ];

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  })

  const [whelpsContractEth, setWhelpsContractEth] = useState(null)
  const [whelpsContractPol, setWhelpsContractPol] = useState(null)
  const [stakingContractEthV1, setStakingContractEthV1] = useState(null)
  const [stakingContractEthV2, setStakingContractEthV2] = useState(null)
  const [stakingContractPolV1, setStakingContractPolV1] = useState(null)
  const [stakingContractPolV2, setStakingContractPolV2] = useState(null)

  const [ethTokens, setEthTokens] = useState([])
  const [polTokens, setPolTokens] = useState([])
  const [ethPools, setEthPools] = useState([])
  const [polPools, setPolPools] = useState([])
  const [whelps, setWhelps] = useState(initWhelps)
  const [showDragons, setShowDragons] = useState(false)
  const [currentHolder, setCurrentHolder] = useState(INVALID_ID)

  useEffect(async () => {
    const initialHandler = async () => {
      if (![1, 4, 137].includes(chainId))
        return

      const CONTRACT_ADDRESS_STAKING_ETH_V1 = addresses.contract_staking[1];
      const CONTRACT_ADDRESS_STAKING_ETH_V2 = addresses.contract_staking_v2[1];
      const CONTRACT_ADDRESS_STAKING_POL_V1 = addresses.contract_staking[137];
      const CONTRACT_ADDRESS_STAKING_POL_V2 = addresses.contract_staking_v2[137];

      const _whelpsContractEth = new ethWeb3.eth.Contract(
        abi_whelps,
        CONTRACT_ADDRESS_WHELPS_Eth
      )

      const _whelpsContractPol = new polWeb3.eth.Contract(
        abi_whelps,
        CONTRACT_ADDRESS_WHELPS_Pol
      )

      const _stakingContractEthV1 = new ethWeb3.eth.Contract(
        abi_staking,
        CONTRACT_ADDRESS_STAKING_ETH_V1
      )

      const _stakingContractEthV2 = new ethWeb3.eth.Contract(
        abi_staking,
        CONTRACT_ADDRESS_STAKING_ETH_V2
      )

      const _stakingContractPolV1 = new polWeb3.eth.Contract(
        abi_staking,
        CONTRACT_ADDRESS_STAKING_POL_V1
      )

      const _stakingContractPolV2 = new polWeb3.eth.Contract(
        abi_staking,
        CONTRACT_ADDRESS_STAKING_POL_V2
      )

      setWhelpsContractEth(_whelpsContractEth)
      setWhelpsContractPol(_whelpsContractPol)
      setStakingContractEthV1(_stakingContractEthV1)
      setStakingContractEthV2(_stakingContractEthV2)
      setStakingContractPolV1(_stakingContractPolV1)
      setStakingContractPolV2(_stakingContractPolV2)
    }

    if (connected && ethWeb3 !== null && polWeb3 !== null) {
      initialHandler()
    }
  }, [connected, chainId, ethWeb3, polWeb3]);

  useEffect(() => {
    refreshTokens();
  }, [whelpsContractEth])

  const refreshTokens = async () => {
    if (whelpsContractEth === null || whelpsContractPol === null || stakingContractEthV1 === null || stakingContractEthV2 === null || stakingContractPolV1 === null || stakingContractPolV2 === null)
      return

    try {
      const ethTokens = await whelpsContractEth.methods
        .balanceOf(walletAddress)
        .call();

      let results = [];
      for (let i = 0; i < ethTokens; i++) {
        results.push(refreshTokenByIndex(whelpsContractEth, stakingContractEthV2, i, eth_chain_id));
      }

      await Promise.all(results);

      const polTokens = await whelpsContractPol.methods
        .balanceOf(walletAddress)
        .call();

      results = [];
      for (let i = 0; i < polTokens; i++) {
        results.push(refreshTokenByIndex(whelpsContractPol, stakingContractPolV2, i, pol_chain_id));
      }

      await Promise.all(results);

      setEthPools([]);
      setPolPools([]);

      const latest = await web3.eth.getBlock("latest");
      const options = {
        filter: { _owner: walletAddress },
        fromBlock: 1,
        toBlock: latest.number,
      };
      const events = await stakingContractEthV2.getPastEvents('Stake', options);
      let stakingEthPools = [];
      if (events.length > 0) {
        for (let event of events) {
          stakingEthPools.push(event.returnValues._stakingSetKey);
        }
      }
      stakingEthPools = [...new Set(stakingEthPools)];
      let stakePoolFetch = [];
      for (let j = 0; j < stakingEthPools.length; j++) {
        stakePoolFetch.push(stakePoolEthFetchHandler(stakingContractEthV2, whelpsContractEth, stakingEthPools[j]));
      }
      await Promise.all(stakePoolFetch);
      const stakingPolPools = await stakingContractPolV2.methods
      .stakingPoolsForUser(walletAddress)
      .call();
      stakePoolFetch = [];
      for (let j = 0; j < stakingPolPools.length; j++) {
        stakePoolFetch.push(stakePoolFetchHandler(whelpsContractPol, stakingPolPools, j));
      }
      await Promise.all(stakePoolFetch);

    } catch (e) {
      console.log(e);
    }
  }

  const refreshTokenByIndex = async (whelpsContract, stakingContract, index, chainId) => {
    const tokenId = await whelpsContract.methods
      .tokenOfOwnerByIndex(walletAddress, index)
      .call();

    const metadataUrl = await whelpsContract.methods
      .tokenURIComputedName(tokenId)
      .call();

    const isStaked = await stakingContract.methods
      .tokenIdHasBeenStaked(tokenId)
      .call();

    const whelp = parseMetadata(tokenId, metadataUrl, isStaked);

    if (chainId === eth_chain_id) {
      setEthTokens(prevTokens => {
        let array = [...prevTokens];
        const _i = array.findIndex((elem) => elem.tokenId === tokenId);
        if (_i != -1) {
          array.splice(_i, 1, whelp);
        } else {
          array.push(whelp);
          array.sort((a, b) => a.tokenId - b.tokenId);
        }
        return array;
      })
    } else if (chainId === pol_chain_id) {
      setPolTokens(prevTokens => {
        let array = [...prevTokens];
        const _i = array.findIndex((elem) => elem.tokenId === tokenId);
        if (_i != -1) {
          array.splice(_i, 1, whelp);
        } else {
          array.push(whelp);
          array.sort((a, b) => a.tokenId - b.tokenId);
        }
        return array;
      })
    }
  }

  const stakePoolEthFetchHandler = async (stakingContract, whelpsContract, poolId) => {
    let poolTokens = []
    const metaDataFetch = []
    const tokenIds = await stakingContract.methods.tokenIdsFromStakingSet(poolId).call()
    const stakingInfo = await stakingContract.methods.keyToStakingSet(poolId).call()

    for (let i = 0; i < tokenIds.length; i++) {
      const tok = tokenIds[i];
      metaDataFetch.push(metaDataFetchHandler(whelpsContract, tok))
    }
    poolTokens = await Promise.all(metaDataFetch)

    setEthPools(prev => {
      const newPools = [...prev]

      if (newPools.find((p) => p.tokenId == stakingInfo.tokenId)) return newPools;

      newPools.push({
        id: prev.length.toString(),
        tokenId: stakingInfo.tokenId,
        progress: Math.min(Math.floor((Date.now() / 1000 - parseInt(stakingInfo.timestamp)) / (EGG_CLAIM_TIMESTAMPS[tokenIds.length - 1]) * 100), 100),
        targetEggTime: (parseInt(stakingInfo.timestamp) + EGG_CLAIM_TIMESTAMPS[tokenIds.length - 1]),
        targetWhelpsTime: (parseInt(stakingInfo.timestamp) + EGG_CLAIM_TIMESTAMPS[tokenIds.length - 1] + WHELPS_CLAIM_TIMESTAMP + 30),
        dragons: poolTokens,
        eggClaimed: stakingInfo.eggClaimed,
        whelpsClaimed: stakingInfo.tokensClaimed,
      })

      return newPools;
    });
  }

  const stakePoolFetchHandler = async (whelpsContract, stakingPools, j) => {
    let poolTokens = []
    const metaDataFetch = []

    for (let i = 0; i < stakingPools[j].tokenIds.length; i++) {
      const tok = stakingPools[j].tokenIds[i];
      metaDataFetch.push(metaDataFetchHandler(whelpsContract, tok))
    }
    poolTokens = await Promise.all(metaDataFetch)

    setPolPools(prev => {
      const newPools = [...prev]

      if (newPools.find((p) => p.tokenId == stakingPools[j].tokenId)) return newPools;

      newPools.push({
        id: prev.length.toString(),
        tokenId: stakingPools[j].tokenId,
        progress: Math.min(Math.floor((Date.now() / 1000 - parseInt(stakingPools[j].timestamp)) / (EGG_CLAIM_TIMESTAMPS[stakingPools[j].tokenIds.length - 1]) * 100), 100),
        targetEggTime: (parseInt(stakingPools[j].timestamp) + EGG_CLAIM_TIMESTAMPS[stakingPools[j].tokenIds.length - 1]),
        targetWhelpsTime: (parseInt(stakingPools[j].timestamp) + EGG_CLAIM_TIMESTAMPS[stakingPools[j].tokenIds.length - 1] + WHELPS_CLAIM_TIMESTAMP + 30),
        dragons: poolTokens,
        eggClaimed: stakingPools[j].eggClaimed,
        whelpsClaimed: stakingPools[j].tokensClaimed,
      })

      return newPools;
    });
  }

  const metaDataFetchHandler = async (whelpsContract, tok) => {
    const metadataUrl = await whelpsContract.methods
      .tokenURIComputedName(tok)
      .call();

    return parseMetadata(tok, metadataUrl, true);
  }

  const parseMetadata = (tokenId, metadataUrl, isStaked) => {
    const parts = metadataUrl.split('-');

    const whelp = {
      name: parts[0],
      image: "https://whelps.mypinata.cloud/ipfs/" + img_hashes[parts.join('-')],
      address: `#${tokenId}`,
      selected: false,
      staked: isStaked,
      tokenId: tokenId,
    }

    return whelp;
  }

  const handleEthClaimEgg = async (stakingKey) => {
    if (!connected || stakingContractEthV2 === null)
      return;

    if (typeof stakingKey == undefined) {
      return;
    }

    if(chainId !== 1) {
      displayNotify("warning", "You should choose the Ethereum Mainnet")
      return;
    }

    const CONTRACT_ADDRESS_STAKING_ETH_V2 = addresses.contract_staking_v2[chainId];
    const contract =  new web3.eth.Contract(
      abi_staking,
      CONTRACT_ADDRESS_STAKING_ETH_V2
    )

    await contract.methods
      .claimEgg(stakingKey)
      .send({ from: walletAddress })
      .on("error", error => {
        console.log(error)
        displayNotify("error", `Error ${error.code}: ${error.message}`)
      })
      .on("transactionHash", hash => {
        const newEthPools = ethPools.map((p) => {
          if (p.tokenId !== stakingKey) {
            return p;
          }
          p.claimEggInProgress = true;
          return p;
        })
        setEthPools(newEthPools);
      })
      .on("receipt", receipt => {
        const newEthPools = ethPools.map((p) => {
          if (p.tokenId !== stakingKey) {
            return p;
          }
          p.claimEggInProgress = false;
          p.eggClaimed = true;
          return p;
        })
        setEthPools(newEthPools);
      });
  }

  const handlePolClaimEgg = async (stakingKey) => {
    if (!connected || stakingContractPolV2 === null)
      return;

    if (typeof stakingKey == undefined) {
      return;
    }
    
    if(chainId !== 137) {
      displayNotify("warning", "You should choose the Polygon Mainnet")
      return;
    }

    const _stakingContractPolV2 = addresses.contract_staking_v2[chainId];
    const contract =  new web3.eth.Contract(
      abi_staking,
      _stakingContractPolV2
    )

    await contract.methods
      .claimEgg(stakingKey)
      .send({ from: walletAddress })
      .on("error", error => {
        console.log(error)
        displayNotify("error", `Error ${error.code}: ${error.message}`)
      })
      .on("transactionHash", hash => {
        const newPolPools = polPools.map((p) => {
          if (p.tokenId !== stakingKey) {
            return p;
          }
          p.claimEggInProgress = true;
          return p;
        })
        setPolPools(newPolPools);
      })
      .on("receipt", receipt => {
        const newPolPools = polPools.map((p) => {
          if (p.tokenId !== stakingKey) {
            return p;
          }
          p.claimEggInProgress = false;
          p.eggClaimed = true;
          return p;
        })
        setPolPools(newPolPools);
      });
  }

  const handleEthClaimWhelps = async (stakingKey) => {
    if (!connected || stakingContractEthV1 === null || stakingContractEthV2 === null)
      return;

    if (typeof stakingKey == undefined) {
      return;
    }

    if(chainId !== 1) {
      displayNotify("warning", "You should choose the Ethereum Mainnet")
      return;
    }

    const CONTRACT_ADDRESS_STAKING_ETH_V1 = addresses.contract_staking[chainId];
    const CONTRACT_ADDRESS_STAKING_ETH_V2 = addresses.contract_staking_v2[chainId];
    const _stakingContractEthV1 = new web3.eth.Contract(
      abi_staking,
      CONTRACT_ADDRESS_STAKING_ETH_V1
    )
    const _stakingContractEthV2 =  new web3.eth.Contract(
      abi_staking,
      CONTRACT_ADDRESS_STAKING_ETH_V2
    )

    const contract = stakingKey < 5 ? _stakingContractEthV1 : _stakingContractEthV2;
    
    await contract.methods
      .claimStakedTokens(stakingKey)
      .send({ from: walletAddress })
      .on("error", error => {
        console.log(error)
        displayNotify("error", `Error ${error.code}: ${error.message}`)
      })
      .on("transactionHash", hash => {
        const newEthPools = ethPools.map((p) => {
          if (p.tokenId !== stakingKey) {
            return p;
          }
          p.claimWhelpsInProgress = true;
          return p;
        })
        setEthPools(newEthPools);
      })
      .on("receipt", receipt => {
        const newEthPools = ethPools.map((p) => {
          if (p.tokenId !== stakingKey) {
            return p;
          }
          p.claimWhelpsInProgress = false;
          p.whelpsClaimed = true;
          return p;
        })
        setEthPools(newEthPools);
      });
  }

  const handlePolClaimWhelps = async (stakingKey) => {
    if (!connected || stakingContractPolV1 === null || stakingContractPolV2 === null)
      return;

    if (typeof stakingKey == undefined) {
      return;
    }

    if(chainId !== 137) {
      displayNotify("warning", "You should choose the Polygon Mainnet")
      return;
    }

    const CONTRACT_ADDRESS_STAKING_POL_V1 = addresses.contract_staking[chainId];
    const CONTRACT_ADDRESS_STAKING_POL_V2 = addresses.contract_staking_v2[chainId];
    const _stakingContractPolV1 = new web3.eth.Contract(
      abi_staking,
      CONTRACT_ADDRESS_STAKING_POL_V1
    )
    const _stakingContractPolV2 =  new web3.eth.Contract(
      abi_staking,
      CONTRACT_ADDRESS_STAKING_POL_V2
    )

    const contract = stakingKey < 273 ? _stakingContractPolV1 : _stakingContractPolV2;
    
    await contract.methods
      .claimStakedTokens(stakingKey)
      .send({ from: walletAddress })
      .on("error", error => {
        console.log(error)
        displayNotify("error", `Error ${error.code}: ${error.message}`)
      })
      .on("transactionHash", hash => {
        const newPolPools = polPools.map((p) => {
          if (p.tokenId !== stakingKey) {
            return p;
          }
          p.claimWhelpsInProgress = true;
          return p;
        })
        setPolPools(newPolPools);
      })
      .on("receipt", receipt => {
        const newPolPools = polPools.map((p) => {
          if (p.tokenId !== stakingKey) {
            return p;
          }
          p.claimWhelpsInProgress = false;
          p.whelpsClaimed = true;
          return p;
        })
        setPolPools(newPolPools);
      });
  }

  const displayNotify = (type, content) => {
    setAlertState({
      open: true,
      message: content,
      severity: type,
    })
  }

  return (
    <>
      <div className="bg-secondary-whelps">
        <div className="w-full relative">
          <div className={`w-full sm:bg-secondary opacity-30`}>
            <img src="/stake/Old_Lady_Background.png">
            </img>
          </div>
        </div>
        <div className="container mx-auto">
          {ethPools.length > 0 && (
            <p className="text-white text-2xl mt-2 mb-1">
              Ethereum Pools
            </p>
          )}
          <div className="flex flex-col justify-center items-center sm:m-8">
            {
              ethPools.map((dt, idx) => (
                <PoolCard
                  key={idx}
                  {...dt}
                  handleClaimEgg={() => handleEthClaimEgg(dt.tokenId)}
                  handleClaimWhelps={() => handleEthClaimWhelps(dt.tokenId)}
                >
                </PoolCard>
              ))
            }
          </div>
          {polPools.length > 0 && (
            <p className="text-white text-2xl mt-2 mb-1">
              Polygon Pools
            </p>
          )}
          <div className="flex flex-col justify-center items-center sm:m-8">
            {
              polPools.map((dt, idx) => (
                <PoolCard
                  key={idx}
                  {...dt}
                  handleClaimEgg={() => handlePolClaimEgg(dt.tokenId)}
                  handleClaimWhelps={() => handlePolClaimWhelps(dt.tokenId)}
                >
                </PoolCard>
              ))
            }
          </div>

          <div className="container flex flex-row justify-center mx-auto">
            <img src="/stake/old_lady_pet_dragon.png" className="sm:max-w-2xl mt-10 sm:mt-0" alt="" />
          </div>

          <div className="flex flex-row justify-center">
            <div className="mt-8 md:mt-0 text-primary-light">
              <div className="text-2xl tracking-wider font-lemon text-primary-whelps md:text-4xl">
                Story
              </div>

              <div className="max-w-xl mt-12 text-md leading-8 font-roboto italic">
                Legend of the Old Woman
              </div>
              <div className="max-w-5xl mt-12 text-md leading-8 font-roboto italic">
                Although the Highborn dragons themselves were absent from the world for many centuries, people who were interested in their history have, for eons,
                made pilgrimages to a flat plain with an incongruous, pointed hill in its center.
              </div>
              <div className="max-w-5xl mt-12 text-md leading-8 font-roboto italic">
                Whether they come in times of plenty or times of privation, these pilgrims have considered themselves lucky to be greeted by the Old Woman—a
                lady who looks and speaks like she could have stepped out of ancient times herself. In truth, she is the inheritor of a great tradition of old women
                and their families who have lived on this hill, healing the sick and—when dragons fly over the world—playing an important role in the gestation
                and birth of the Ancient breed of dragons.
              </div>
              <div className="max-w-5xl mt-12 text-md leading-8 font-roboto italic">
                While most dragons breed in the usual way of reptiles, with the mother guarding a fertilised clutch of eggs,
                Ancient dragons arise when one or more individuals from the six ordinary dragon breeds offer a special form of
                vital energy that they are born with to the Old Woman, who weaves it into an egg that hatches into one of the Ancients.
              </div>
              <div className="max-w-5xl mt-12 text-md leading-8 font-roboto italic">
                The mighty, wise Ancients serve an important role as spiritual leaders of all dragonkind,
                and the Old Woman serves as their adviser and a symbol of the indelible link between dragons and humans.
                She is a wise counsellor, a staunch ally and an implacable enemy, and enjoys the respect and veneration of many cultures,
                countries and religions.
              </div>

              <div className="text-2xl tracking-wider font-lemon text-primary-whelps md:text-4xl mt-16">
                Frequently Asked Questions
              </div>
              <div className="max-w-5xl">
                <div className="my-6">
                  {faqDataOnStake.map((dt, idx) => (
                    <Question key={idx} {...dt}></Question>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {showDragons && (
          <DragonList
            open={showDragons}
            setOpen={setShowDragons}
            whelps={whelps}
            onSelected={(selectedIndex) => {
              const newDragonList = [...tokens]
              if (selectedIndex !== INVALID_ID) {
                newDragonList[selectedIndex].selected = true
              }
              if (currentHolder !== INVALID_ID && whelps[currentHolder].id !== INVALID_ID) {
                newDragonList[whelps[currentHolder].id].selected = false
              }
              setTokens(newDragonList)
              const newWhelps = [...whelps]
              newWhelps[currentHolder].id = selectedIndex
              setWhelps(newWhelps)
            }}
            dragons={tokens}
          />
        )} */}
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={alertState.open}
          autoHideDuration={10000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
            className="alert-md"
          >
            {alertState.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

const stateProps = (state) => ({
  chainId: state.chainId,
  web3: state.web3,
  ethWeb3: state.ethWeb3,
  polWeb3: state.polWeb3,
  walletAddress: state.walletAddress,
  connected: state.connected
});

export default connect(stateProps, null)(Stake);