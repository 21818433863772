export function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export function remainDays(target) {
  const now = Date.now()
  if (now >= target)
    return pad(0, 2)
  else
    return pad(Math.floor((target - now) / 86400000), 2)
}

export function remainHours(target) {
  const now = Date.now()
  if (now > target)
    return pad(0, 2)
  else
    return pad(Math.floor(((target - now) % 86400000) / 3600000), 2)
}

export function remainMins(target) {
  const now = Date.now()
  if (now > target)
    return pad(0, 2)
  else
    return pad(Math.floor(((target - now) % 3600000) / 60000), 2)
}
